@import './rsc/css/fonts.variables.scss';
@import './rsc/css/_variables.scss';

:root {
  --primary-color: #12A22C;
  --secondary-color: #FFF174;
  --font-nikita: "Nikita", sans-serif;
  --font-nueva: "Nueva", serif;
  --font-proxima-nova: ProximaNova-Regular;
  --header-font: "Nikita", sans-serif;
  --body-font: "Nueva", serif;
  --header-color: #687D8F;
  --body-color: #687D8F;
}

.main-container {
  background-color: $alabaster;
  font-family: var(--body-font);
  color: var(--body-color);
}

//img {
  //max-width: 100%;
  //height: auto;
//}

.button-primary {
  background: var(--primary-color);
  color: $white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  font-weight: 700;
  margin-right: 1rem;
  margin-top: 0.5rem;
}

.button-link {
  color: var(--body-color);
  text-decoration: underline;
  border: none;
  background: none;

  &:hover {
    color: var(--body-color);
  }
}

.main-container {
  .app-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }

  @media only screen and (min-width: $breakpoint-tablet) {
    padding-top: 100px;
  }
}
