@import '../../rsc/css/_variables.scss';

header {
  font-family: var(--header-font);
  color: var(--header-color);
  border-top: 10px solid var(--primary-color);
  border-bottom: 1px solid #e1e1e1;
  background-color: $white;

  @media only screen and (min-width: $breakpoint-tablet) {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
  }
}

.container {
  max-width: $container-width;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  line-height: 23px;

  .logo {
    padding-top: 1.5rem;
  }

  .hamburger {
    display: none;
    padding-top: 2.3rem;
    padding-bottom: 2rem;
    cursor: pointer;
    span {
      margin-bottom: 0.2rem;
      display: block;
      width: 1.4rem;
      height: 2px;
      background-color: var(--primary-color);
    }
  }

  .nav-menu {
    list-style: none;
    
    li {
      display: inline-block;
      padding: 2rem 1rem 1rem;

      a {
        text-decoration: none;
        //color: $fiord;
        color: var(--header-color);
        font-weight: 500;
        font-size: 16px;

        &:hover {
          opacity: .7;
          transition: all .4s ease-in-out;
        }
      }
    }
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    position: relative;
    .hamburger {
      display: block;
    }

    .nav-menu {
      display: none;
    }

    .nav-menu-opened {
      position: absolute;
      display: block;
      background: $white;
      border-top: 3px solid var(--primary-color);
      top: 84px;
      width: 100%;
      z-index: 2;
      padding: 5%;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      transition: max-height 0.3s ease-in-out;
      
      li {
        display: block;
        padding: 10px 5%;
        border-bottom: 1px solid rgba(0,0,0,.03);
        //color: #666;
      }
    }
  }
}

