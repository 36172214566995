@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;700;800;900&display=swap');

@font-face {
  font-family: 'fontello';
  src: local('fontello'), url(../fonts/fontello.woff2) format('woff2');
}

@font-face {
  font-family: 'Nikita';
  src: url('../fonts/Nikita_Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nikita';
  src: url('../fonts/Nikita_Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Nikita';
  src: url('../fonts/Nikita_Light.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nueva';
  src: url('../fonts/Nueva_Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nueva';
  src: url('../fonts/Nueva_Regular.woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nueva';
  src: url('../fonts/Nueva_Semibold.woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nueva';
  src: url('../fonts/Nueva_Bold.woff');
  font-weight: 700;
  font-style: normal;
}

