@import '../../rsc/css/_variables.scss';

footer {
  font-family: var(--header-font);
}

.footer-bottom-container {
  background: #101314;
  padding: 64px 0;

  .logo-container {
    display: flex;
    align-items: center;

    .logo {
      margin-right: 1rem;
    }

    p {
      margin-top: 5px;
      margin-bottom: 0;
      color: $white;
      opacity: 0.6;
    }

    @media only screen and (max-width: 1024px) {
      flex-direction: column;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      flex-direction: column;
      margin: 1.7rem 0;
    }
  }

  .footer-content {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: $breakpoint-tablet) {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  .social-media {
    display: flex;

    a {
      color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border: 1px solid #343637;
      border-radius: 50%;
      cursor: pointer;
      margin-right: 0.8rem;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      margin: 1.7rem 0;
    }

  }

  .nav-menu {
    list-style: none;
    margin-bottom: 0;
    margin-top: 13px;
    
    li {
      display: inline-block;
      padding: 0 1rem;

      a {
        text-decoration: none;
        color: $white;
        opacity: 0.6;
      }
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      margin: 1.7rem 0;
      padding: 0;
    }
  }

}


