$container-width: 1080px;

$fiord: #3B4660;
$salem: #12A22C;
$white: #ffffff;
$slate-gray: #708096;
$heading-color: #3b4660; 
$alabaster: #fafafa;
$fontNikita: "Nikita", sans-serif;
$fontNueva: "Nueva", serif;
$fontProximaNova: ProximaNova-Regular;

$breakpoint-tablet: 992px;
$bs-breakpoint-tablet: 960px;

$titleColor: #3b4660;
$subtitleColor: #708096;
